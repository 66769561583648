var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10","offset-md":"1"}},[(_vm.currEvent[0])?_c('v-card',[_c('v-card-title',{staticClass:"text-uppercase"},[_c('v-btn',{staticClass:"mr-3",attrs:{"text":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" Back ")],1),_vm._v(" Event Code: "+_vm._s(_vm.currEvent[0].no)+"   "),_c('v-chip',{attrs:{"dark":"","color":_vm.currEvent[0].status === 'Active'
                ? 'green darken-1'
                : 'grey darken-1'}},[_vm._v(" "+_vm._s(_vm.currEvent[0].status))])],1),_c('v-divider'),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12","md":"10","offset-md":"1"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Event")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.currEvent[0].description)+" ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Dates")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.formatDate(_vm.currEvent[0].startDate, 6))+" - "+_vm._s(_vm.formatDate(_vm.currEvent[0].endDate, 6))+" ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Location")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.currEvent[0].location)+" ")])],1)],1),_c('v-img'),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Bookings")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.currEvent[0].bookings)+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"mb-1",attrs:{"outlined":"","color":"primary","block":""},on:{"click":function($event){return _vm.getReport('eventAttandance', _vm.currEvent[0].no)}}},[_vm._v("ATTENDANCE report")]),_c('v-btn',{staticClass:"mb-1",attrs:{"outlined":"","color":"primary","block":""},on:{"click":function($event){return _vm.getReport('dailyAttendanceReport', _vm.currEvent[0].no)}}},[_vm._v("ATTENDANCE PER DAY")]),_c('v-btn',{staticClass:"mb-1",attrs:{"outlined":"","color":"primary","block":""},on:{"click":function($event){return _vm.getReport(
                        'attendancePackageConsumption',
                        _vm.currEvent[0].no
                      )}}},[_vm._v("PACKAGE CONSUMPTION")]),_c('v-btn',{staticClass:"mb-1",attrs:{"outlined":"","color":"primary","block":"","to":{
                      name: 'DelegatesBio',
                      params: { event: _vm.currEvent[0].no },
                    }}},[_vm._v("Delegates")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 my-10",attrs:{"headers":_vm.headers,"items":_vm.filterPackages(_vm.currEvent[0].eventPackageCost),"hide-default-footer":"","items-per-page":100},scopedSlots:_vm._u([{key:"item.mandatory",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.mandatory ? "Yes" : "No ")+" ")]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{attrs:{"to":{
                    name: 'Delegates',
                    params: { event: item.eventNo, package: item.packageNo },
                  },"dark":"","color":"primary"}},[_vm._v(" Check in "),_c('v-icon',[_vm._v("mdi-arrow-right")])],1)]}}],null,false,1230988243)})],1)],1)],1):_c('v-card',[_c('v-skeleton-loader',{attrs:{"type":"card-heading, table"}})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"450","disabled":_vm.$store.getters['loading']},model:{value:(_vm.reportDialog),callback:function ($$v) {_vm.reportDialog=$$v},expression:"reportDialog"}},[_c('v-card',{attrs:{"loading":_vm.$store.getters['loading']}},[_c('v-card-title',{staticClass:"text-h6 text-capitalize"},[_vm._v(" Report "),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.reportDialog = false}}},[_vm._v("Close")])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","href":_vm.report,"color":"primary"}},[_vm._v(" Download "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-download")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":"","target":"_blank","href":("https://docs.google.com/gview?url=" + _vm.report)}},[_vm._v(" Open "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-link")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
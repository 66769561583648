import call from "../../service/http";
import constants from "./DashboardConstants";

export default {
  namespaced: true,
  state: {
    events: [],
    emsEvents: [],
    eventsTypes: [],
    delegates: {},
    users: [],
    requestStack: [],
    requestErrors: [],
    report: "",
    qrTag: "",
    alert: {
      status: "",
      message: "",
    },
  },
  mutations: {
    SET_EVENTS: (state, payload) => {
      state.events = payload;
    },

    SET_REPORT: (state, payload) => {
      state.report = payload;
    },

    SET_QR_TAG: (state, payload) => {
      state.qrTag = payload;
    },

    SET_EMS_EVENTS: (state, payload) => {
      state.emsEvents = payload;
    },

    SET_EVENT_TYPES: (state, payload) => {
      state.eventsTypes = payload;
    },

    SET_DELEGATES: (state, payload) => {
      state.delegates = payload;
    },

    SET_USERS: (state, payload) => {
      state.users = payload;
    },

    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };

      setTimeout(() => {
        state.alert = { status: "", message: "" };
      }, 2000);
    },

    SET_ATTENDANCE: (state, payload) => {
      state.delegates.data.forEach((del) => {
        if (del.delegateNo === payload.delegateNo)
          del.eventDelegate.delegateAttendance.push(payload);
      });

      Event.$emit("delegate-checked-in");
    },

    SET_REQUEST_STACK: (state, payload) => {
      state.requestStack.push(payload);
    },

    SET_REQUEST_ERROR: (state, payload) => {
      state.requestErrors.push(payload);
    },

    UNSET_REQUEST_IN_STACK: (state, payload) => {
      state.requestStack.splice(state.requestStack.indexOf(payload), 1);
    },
  },
  getters: {
    events: (state) => state.events,
    emsEvents: (state) => state.emsEvents,
    eventsTypes: (state) => state.eventsTypes,
    delegateS: (state) => state.delegates,
    users: (state) => state.users,
    alert: (state) => state.alert,
    report: (state) => state.report,
    qrTag: (state) => state.qrTag,
    requestStack: (state) => state.requestStack,
  },
  actions: {
    getEvents: ({ commit }, no) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.events(no))
        .then((res) => {
          commit("SET_EVENTS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    getEventTypes: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.eventTypes)
        .then((res) => {
          commit("SET_EVENT_TYPES", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    getEventDelegates: ({ commit }, params) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.delegates(params))
        .then((res) => {
          commit("SET_DELEGATES", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    getPackageStats: ({ commit }, params) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.packageStats(params))
        .then((res) => {
          Event.$emit("package-stats", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    // eslint-disable-next-line no-unused-vars
    submitAttendance: ({ commit, dispatch }, data) => {
      commit("SET_ATTENDANCE", data);
      commit("SET_REQUEST_STACK", {
        method: "post",
        endpoint: constants.attendance,
        payload: data,
      });
    },

    getDelegate: ({ commit }, data) => {
      call("get", constants.getDelegate(data))
        .then((res) => {
          Event.$emit("scanned-delegate", res.data.data);
          commit("SET_LOADING", false, { root: true });
          this.$store.commit("Dashboard/SET_ALERT", {
            status: "success",
            message: "Message updated successfully",
          });
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    getUsers: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.users)
        .then((res) => {
          commit("SET_USERS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    saveUser: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.saveUser, data)
        .then(() => {
          dispatch("getUsers");
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    removeUser: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.remove, data)
        .then(() => {
          dispatch("getUsers");
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    getEmsEvents: ({ commit }, year) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.ems_events(year))
        .then((res) => {
          commit("SET_EMS_EVENTS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    uploadExcel: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.uploadExcel(data.id), data.data)
        .then(() => {
          dispatch("getEmsEvents", new Date().getFullYear());
          Event.$emit("excel-uploaded");
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    mapEmsToBC: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.mapEmsToBC, data)
        .then(() => {
          dispatch("getEmsEvents", new Date().getFullYear());
          Event.$emit("ems-to-bc-mapped");
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    sycToBC: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.sync(data), data)
        .then(() => {
          dispatch("getEmsEvents", new Date().getFullYear());
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    getReport: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.report, data)
        .then((res) => {
          commit("SET_REPORT", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    getQrTag: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.qrTag, data)
        .then((res) => {
          commit("SET_QR_TAG", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },
  },
};
